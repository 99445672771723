body{
  background-color: #111315;
}


@font-face {
  font-family: Poppins;
  src: url(../public/fonts/Roboto-Condensed.ttf);
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.bg-techWallpaper{
  background-image: url(./Components/Assets/tech4.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-mask-image: linear-gradient(to top, transparent 1%, #111315 80%);
  mask-image: linear-gradient(to top, transparent 1%, #111315 80%);
}
.bg-serviceWallpaper{
  background-image: url(./Components/Assets/10881763.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.active-link{
  background-color: white;
  color: black;
}

.active{
  color: #207ead;
  font-weight: bold;
  border-bottom: 2px solid #207ead;
}

@media (min-width: 1024px) {
  .firstServiceImage {
    -webkit-mask-image: linear-gradient(to left, transparent 10%, #111315 100%);
    mask-image: linear-gradient(to left, transparent 10%, #111315 100%);
  }

  .secondServiceImage{
    -webkit-mask-image: linear-gradient(to right, transparent 10%, #111315 100%);
    mask-image: linear-gradient(to right, transparent 10%, #111315 100%);
  }
}

